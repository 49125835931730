import ApiCommerce from '@/core/apis/ApiCommerce';
import { IPreco } from '@/models/Entidades/IPreco';
import { IResposta } from '@/core/models/IResposta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IRepostaConsulta } from '@/core/models/IRepostaConsulta';
import { IPrecoParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/IPrecoParametrosConsulta';
import { IDTOPreco } from '@/models/DTO/IDTOPreco';
import { IParametrosBuscaRapida } from '@/core/models/Consulta/IParametrosBuscaRapida';
import { IItemBuscaRapida } from '@/core/models/Consulta/IItemBuscaRapida';
/**
 * Serviço de Precos
 */

class ServicoPreco implements IServicoBase<IPreco> {
  endPoint = 'precos';

  apiCommerce = new ApiCommerce();

  public async incluir(preco: IPreco): Promise<IResposta> {
    const result: any = await this.apiCommerce.post(`${this.endPoint}`, preco);
    return result.data;
  }

  public async atualizar(preco: IPreco): Promise<IResposta> {
    const result: any = await this.apiCommerce.put(`${this.endPoint}/${preco.codigo}`, preco);
    return result.data;
  }

  public async obter(codigo: number): Promise<IPreco> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterPrecos(parametrosConsulta: IPrecoParametrosConsulta): Promise<IRepostaConsulta<IDTOPreco>> {
    let parametrosAdicionais = '';

    if (parametrosConsulta.valorPesquisa !== undefined) {
      parametrosAdicionais += `?ValorPesquisa=${parametrosConsulta.valorPesquisa}`;
    }

    if (parametrosConsulta.status !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Status=${parametrosConsulta.status}`;
    }

    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigos !== undefined) {
      parametrosConsulta.codigos.forEach((codigo) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Codigos=${codigo}`;
      });
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}${parametrosAdicionais}`);
    return result.data;
  }

  public async buscaRapida(parametrosConsultaRapida: IParametrosBuscaRapida): Promise<IItemBuscaRapida[]> {
    const parametros = this.apiCommerce.obterParametrosBuscaRapida(parametrosConsultaRapida);
    const result: any = await this.apiCommerce.get(`${this.endPoint}/busca-rapida${parametros}`);
    return result.data;
  }

  public async obterTodos(): Promise<IDTOPreco[]> {
    const parametrosConsulta: IPrecoParametrosConsulta = {} as IPrecoParametrosConsulta;
    parametrosConsulta.qtdeRegistrosPagina = 9999999;
    parametrosConsulta.qtdeRegistrosTotal = 9999999;
    const respostaPrecos = await this.obterPrecos(parametrosConsulta);
    return respostaPrecos.dados;
  }

  public async deletar(codigo: number): Promise<IResposta> {
    const result: any = await this.apiCommerce.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }
}

export default ServicoPreco;
