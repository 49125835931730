import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ant-input-wrapper ant-input-group ui-ta-right"
}
const _hoisted_2 = {
  key: 1,
  class: "ant-input-wrapper ant-input-group ui-ta-right"
}
const _hoisted_3 = {
  key: 2,
  class: "ui-ta-right"
}
const _hoisted_4 = ["title", "data-coluna"]
const _hoisted_5 = {
  key: 3,
  class: "ant-input-wrapper ant-input-group ui-ta-right"
}
const _hoisted_6 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_money3 = _resolveComponent("money3")!
  const _directive_maska = _resolveDirective("maska")!

  return (_ctx.props.monetario)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass('ant-input-group-addon ' + _ctx.props.classGroupCss)
        }, "R$", 2),
        _createVNode(_component_money3, _mergeProps({
          ref: "refCampoNumerico",
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.change())),
          onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.valorDigitado($event))),
          onKeydown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.valorDigitado($event))),
          class: 'ant-input ' + _ctx.props.classCss,
          modelValue: _ctx.computedValor,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedValor) = $event)),
          modelModifiers: { number: true },
          title: _ctx.props.title,
          "data-coluna": _ctx.props.dataAttributeColuna,
          onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.blur()))
        }, _ctx.obterConfiguracao()), null, 16, ["class", "modelValue", "title", "data-coluna"])
      ]))
    : (_ctx.props.percentual)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_money3, _mergeProps({
            ref: "refCampoNumerico",
            onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.change())),
            onKeyup: _cache[6] || (_cache[6] = ($event: any) => (_ctx.valorDigitado($event))),
            onKeydown: _cache[7] || (_cache[7] = ($event: any) => (_ctx.valorDigitado($event))),
            class: 'ant-input ' + _ctx.props.classCss,
            modelValue: _ctx.computedValor,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedValor) = $event)),
            modelModifiers: { number: true },
            title: _ctx.props.title,
            "data-coluna": _ctx.props.dataAttributeColuna,
            onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.blur()))
          }, _ctx.obterConfiguracao()), null, 16, ["class", "modelValue", "title", "data-coluna"]),
          _createElementVNode("span", {
            class: _normalizeClass('ant-input-group-addon ' + _ctx.props.classGroupCss)
          }, "%", 2)
        ]))
      : (_ctx.props.mascara !== '' && _ctx.props.mascara !== undefined)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              ref: "refCampoNumerico",
              onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.change())),
              onKeyup: _cache[11] || (_cache[11] = ($event: any) => (_ctx.valorDigitado($event))),
              onKeydown: _cache[12] || (_cache[12] = ($event: any) => (_ctx.valorDigitado($event))),
              type: "text",
              class: _normalizeClass('ant-input ' + _ctx.props.classCss),
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.computedValor) = $event)),
              title: _ctx.props.title,
              "data-coluna": _ctx.props.dataAttributeColuna,
              onBlur: _cache[14] || (_cache[14] = ($event: any) => (_ctx.blur()))
            }, null, 42, _hoisted_4), [
              [_vModelText, _ctx.computedValor],
              [_directive_maska, _ctx.props.mascara]
            ])
          ]))
        : (_ctx.props.info !== '' && _ctx.props.info !== undefined)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createElementVNode("span", {
                class: _normalizeClass('ant-input-group-addon ' + _ctx.props.classGroupCss)
              }, _toDisplayString(_ctx.props.info), 3),
              _createVNode(_component_money3, _mergeProps({
                ref: "refCampoNumerico",
                onChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.change())),
                onKeyup: _cache[16] || (_cache[16] = ($event: any) => (_ctx.valorDigitado($event))),
                onKeydown: _cache[17] || (_cache[17] = ($event: any) => (_ctx.valorDigitado($event))),
                class: 'ant-input ' + _ctx.props.classCss,
                modelValue: _ctx.computedValor,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.computedValor) = $event)),
                modelModifiers: { number: true },
                title: _ctx.props.title,
                "data-coluna": _ctx.props.dataAttributeColuna,
                onBlur: _cache[19] || (_cache[19] = ($event: any) => (_ctx.blur()))
              }, _ctx.obterConfiguracao()), null, 16, ["class", "modelValue", "title", "data-coluna"])
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, [
              _createVNode(_component_money3, _mergeProps({
                ref: "refCampoNumerico",
                onChange: _cache[20] || (_cache[20] = ($event: any) => (_ctx.change())),
                onKeyup: _cache[21] || (_cache[21] = ($event: any) => (_ctx.valorDigitado($event))),
                onKeydown: _cache[22] || (_cache[22] = ($event: any) => (_ctx.valorDigitado($event))),
                class: 'ant-input ui-ta-right ' + _ctx.props.classCss,
                modelValue: _ctx.computedValor,
                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.computedValor) = $event)),
                modelModifiers: { number: true },
                title: _ctx.props.title,
                "data-coluna": _ctx.props.dataAttributeColuna,
                onBlur: _cache[24] || (_cache[24] = ($event: any) => (_ctx.blur()))
              }, _ctx.obterConfiguracao()), null, 16, ["class", "modelValue", "title", "data-coluna"])
            ]))
}