
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IParceiro } from '@/models/Entidades/IParceiro';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoApp from '@/servicos/ServicoApp';
import ServicoUtilitario from '@/servicos/ServicoUtilitario';
import { IResposta } from '@/core/models/IResposta';
import ComboTipoPessoa from '@/components/ComboTipoPessoa.vue';
import CampoCep from '@/core/components/UI/CampoCep.vue';
import CampoCpf from '@/core/components/UI/CampoCpf.vue';
import CampoCnpj from '@/core/components/UI/CampoCnpj.vue';
import CampoTelefone from '@/core/components/UI/CampoTelefone.vue';
import ComboCidade from '@/components/ComboCidade.vue';
import CampoEmail from '@/core/components/UI/CampoEmail.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'MeuNegocio',
  components: {
    ComunicacaoApi,
    ComboTipoPessoa,
    CampoCep,
    CampoCpf,
    CampoCnpj,
    CampoTelefone,
    ComboCidade,
    CampoEmail,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta,
    } = useAppBase();
    const servicoApp = new ServicoApp();
    const servicoUtilitario = new ServicoUtilitario();
    const state = reactive({
      parceiro: {} as IParceiro,
      cep: '',
      cpfCnpj: '',
    });

    function limparDados() {
      state.parceiro = {} as IParceiro;
      state.parceiro.codigo = 0;
      state.parceiro.codigoCidade = 0;
      state.parceiro.whatsapp = '';
      state.parceiro.whatsappMensagem = '';
      state.cpfCnpj = '';
      state.cep = '';
    }

    onBeforeMount(async () => {
      limparDados();
      appBase.carregando = true;
      state.parceiro = await servicoApp.obterDadosMeuNegocio();
      state.cpfCnpj = state.parceiro.cpfCnpj;
      state.cep = state.parceiro.cep;
      appBase.carregando = false;
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as suas informações.');
      appBase.resposta = await servicoApp.atualizarDadosMeuNegocio(state.parceiro);
      apresentarResposta();
    }

    async function consultaCep() {
      if (UtilitarioGeral.valorValido(state.parceiro.cep) && UtilitarioGeral.validaValorComLimiteCaracteres(state.parceiro.cep, 8)) {
        if (state.parceiro.cep !== state.cep) {
          const retornoConsultaCep = await servicoUtilitario.consultaCep(state.parceiro.cep);
          if (retornoConsultaCep !== undefined) {
            state.parceiro.endereco = retornoConsultaCep.logradouro;
            state.parceiro.complemento = retornoConsultaCep.complemento;
            state.parceiro.bairro = retornoConsultaCep.bairro;
            state.parceiro.codigoCidade = retornoConsultaCep.codigoCidade;
            state.cep = state.parceiro.cep;
          }
        }
      }
    }

    async function consultaCnpj() {
      if (UtilitarioGeral.valorValido(state.parceiro.cpfCnpj) && UtilitarioGeral.validaValorComLimiteCaracteres(state.parceiro.cpfCnpj, 14)) {
        if (state.parceiro.cpfCnpj !== state.cpfCnpj) {
          const retornoConsultaCnpj = await servicoUtilitario.consultaCnpj(state.parceiro.cpfCnpj);
          if (retornoConsultaCnpj !== undefined && UtilitarioGeral.valorValido(retornoConsultaCnpj.razaoSocial)) {
            state.parceiro.nomeRazaoSocial = retornoConsultaCnpj.razaoSocial;
            state.parceiro.email = retornoConsultaCnpj.contato.email;
            state.parceiro.telefone = retornoConsultaCnpj.contato.telefonePrincipal;
            state.parceiro.cep = retornoConsultaCnpj.endereco.cep;
            state.parceiro.numero = retornoConsultaCnpj.endereco.numero;
            state.parceiro.endereco = retornoConsultaCnpj.endereco.logadouro;
            state.parceiro.complemento = retornoConsultaCnpj.endereco.complemento;
            state.parceiro.bairro = retornoConsultaCnpj.endereco.bairro;
            state.parceiro.codigoCidade = retornoConsultaCnpj.endereco.codigoCidade;
            state.cpfCnpj = state.parceiro.cpfCnpj;
          }
        }
      }
    }

    return {
      appBase,
      state,
      salvar,
      consultaCep,
      consultaCnpj,
    };
  },
});
