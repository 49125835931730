
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';

import { useAppBase } from '@/core/composables/AppBase';
import { IConfiguracao, IConfiguracaoNotificacaoEmail, IConfiguracaoSmtp } from '@/models/Entidades/IConfiguracao';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import Card from '@/core/components/UI/Card.vue';
import ServicoApp from '@/servicos/ServicoApp';
import { IResposta } from '@/core/models/IResposta';
import CampoEmail from '@/core/components/UI/CampoEmail.vue';
import CampoSenha from '@/core/components/UI/CampoSenha.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import { ETipoNotificacaoEmail } from '@/models/Enumeradores/ETipoNotificacaoEmail';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export interface IVariavelEmail {
  variavel: string;
  descricao: string;
  tiposNotificacoes: ETipoNotificacaoEmail[];
}

export default defineComponent({
  name: 'ConfiguracoesEmails',
  components: {
    ComunicacaoApi,
    Card,
    CampoEmail,
    CampoSenha,
    EditorHtml,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta,
    } = useAppBase();
    const servicoApp = new ServicoApp();

    const state = reactive({
      configuracao: {} as IConfiguracao,
      notificacaoEmailEmEdicao: {} as IConfiguracaoNotificacaoEmail,
      apresentarModalNotificacao: false,
      variaveis: [] as IVariavelEmail[],
      variaveisTipoNotificacao: [] as IVariavelEmail[],
    });

    function limparDados() {
      state.apresentarModalNotificacao = false;
      state.configuracao = {} as IConfiguracao;
      state.configuracao.codigo = 0;
      state.configuracao.smtp = {} as IConfiguracaoSmtp;
      state.configuracao.notificacoesEmails = [];
      state.notificacaoEmailEmEdicao = {} as IConfiguracaoNotificacaoEmail;
      state.variaveisTipoNotificacao = [];
    }

    state.variaveis = [
      { variavel: '{{ Dominio }}', descricao: 'Domínio da Plataforma', tiposNotificacoes: [ETipoNotificacaoEmail.Padrao, ETipoNotificacaoEmail.NovasContas, ETipoNotificacaoEmail.BoasVindas, ETipoNotificacaoEmail.AtivacaoConta, ETipoNotificacaoEmail.RedefinirSenha, ETipoNotificacaoEmail.Contatos, ETipoNotificacaoEmail.Newsletter, ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ NomePlataforma }}', descricao: 'Nome da Plataforma', tiposNotificacoes: [ETipoNotificacaoEmail.Padrao, ETipoNotificacaoEmail.NovasContas, ETipoNotificacaoEmail.BoasVindas, ETipoNotificacaoEmail.AtivacaoConta, ETipoNotificacaoEmail.RedefinirSenha, ETipoNotificacaoEmail.Contatos, ETipoNotificacaoEmail.Newsletter, ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ Titulo }}', descricao: 'Título', tiposNotificacoes: [ETipoNotificacaoEmail.Padrao] },
      { variavel: '{{ Mensagem }}', descricao: 'Mensagem', tiposNotificacoes: [ETipoNotificacaoEmail.Padrao, ETipoNotificacaoEmail.Contatos] },
      { variavel: '{{ Nome }}', descricao: 'Nome', tiposNotificacoes: [ETipoNotificacaoEmail.Newsletter, ETipoNotificacaoEmail.Contatos] },
      { variavel: '{{ Email }}', descricao: 'E-mail', tiposNotificacoes: [ETipoNotificacaoEmail.Newsletter, ETipoNotificacaoEmail.Contatos] },
      { variavel: '{{ Telefone }}', descricao: 'Telefone', tiposNotificacoes: [ETipoNotificacaoEmail.Contatos] },
      { variavel: '{{ Assunto }}', descricao: 'Assunto', tiposNotificacoes: [ETipoNotificacaoEmail.Contatos] },
      { variavel: '{{ ClienteCodigo }}', descricao: 'Código do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovasContas, ETipoNotificacaoEmail.BoasVindas, ETipoNotificacaoEmail.AtivacaoConta, ETipoNotificacaoEmail.RedefinirSenha, ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ ClienteEmail }}', descricao: 'E-mail do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovasContas, ETipoNotificacaoEmail.BoasVindas, ETipoNotificacaoEmail.AtivacaoConta, ETipoNotificacaoEmail.RedefinirSenha, ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ ClienteTelefone }}', descricao: 'Telefone do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovasContas, ETipoNotificacaoEmail.BoasVindas, ETipoNotificacaoEmail.AtivacaoConta, ETipoNotificacaoEmail.RedefinirSenha, ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ ClienteCpfCnpj }}', descricao: 'CPF/CNPJ do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovasContas, ETipoNotificacaoEmail.BoasVindas, ETipoNotificacaoEmail.AtivacaoConta, ETipoNotificacaoEmail.RedefinirSenha, ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ ClienteIdentificacao }}', descricao: 'Identificação do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovasContas, ETipoNotificacaoEmail.BoasVindas, ETipoNotificacaoEmail.AtivacaoConta, ETipoNotificacaoEmail.RedefinirSenha, ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ ClienteNomeRazaoSocial }}', descricao: 'Nome/Razão Social do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovasContas, ETipoNotificacaoEmail.BoasVindas, ETipoNotificacaoEmail.AtivacaoConta, ETipoNotificacaoEmail.RedefinirSenha, ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ ClienteNomeFantasia }}', descricao: 'Nome Fantasia do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovasContas, ETipoNotificacaoEmail.BoasVindas, ETipoNotificacaoEmail.AtivacaoConta, ETipoNotificacaoEmail.RedefinirSenha, ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ ClienteChaveSenha }}', descricao: 'Chave de Recuperação senha do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovasContas, ETipoNotificacaoEmail.BoasVindas, ETipoNotificacaoEmail.AtivacaoConta, ETipoNotificacaoEmail.RedefinirSenha, ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoNumeroIdentificacao }}', descricao: 'Número do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoStatus }}', descricao: 'Status do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoItensSemPreco }}', descricao: 'Produtos do Pedido Sem Preço', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoItensComPreco }}', descricao: 'Produtos do Pedido Com Preço', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoFormaEntrega }}', descricao: 'Forma de Entrega do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoPrazoEntrega }}', descricao: 'Prazo de Entrega do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoEnderecoEntrega }}', descricao: 'Endereço de Entrega do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoFormaPagamento }}', descricao: 'Forma de Pagamento do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoQuantidadeParcelas }}', descricao: 'Quantidade Parcelas do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoTotalFrete }}', descricao: 'Total Frete do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoTotalProdutos }}', descricao: 'Total dos Produtos do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoValorTotal }}', descricao: 'Valor Total do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
      { variavel: '{{ PedidoObservacoes }}', descricao: 'Observações do Pedido', tiposNotificacoes: [ETipoNotificacaoEmail.NovosPedidos, ETipoNotificacaoEmail.PedidoCancelado, ETipoNotificacaoEmail.PedidoAtualizado] },
    ];

    function obterDescricaoTipoNotificacao(tipoNotificacao:ETipoNotificacaoEmail) {
      return servicoApp.obterDescricaoTipoNotificacaoEmail(tipoNotificacao);
    }

    function obterDestintariosTipoNotificacaoEmail(tipoNotificacao:ETipoNotificacaoEmail, destinatarios: string) {
      return servicoApp.obterDestintariosTipoNotificacaoEmail(tipoNotificacao, destinatarios);
    }

    function preencherDestintariosTipoNotificacaoEmail(tipoNotificacao:ETipoNotificacaoEmail) {
      return servicoApp.preencherDestintariosTipoNotificacaoEmail(tipoNotificacao);
    }

    function apresentarModalEdicaoNotificacao(notificacaoEmail:IConfiguracaoNotificacaoEmail) {
      state.notificacaoEmailEmEdicao = UtilitarioGeral.instanciaObjetoLocal(notificacaoEmail);
      state.variaveisTipoNotificacao = [];
      const variaveisNotificacao = UtilitarioGeral.instanciaObjetoLocal(state.variaveis) as IVariavelEmail[];
      variaveisNotificacao.forEach((variavel) => {
        if (variavel.tiposNotificacoes.some((c) => c === notificacaoEmail.tipo)) {
          state.variaveisTipoNotificacao.push(variavel);
        }
      });
      state.apresentarModalNotificacao = true;
    }

    function confirmarAlteracoesNotificaoEmail() {
      const index = state.configuracao.notificacoesEmails.findIndex((c) => c.codigo === state.notificacaoEmailEmEdicao.codigo);
      if (index >= 0) {
        state.configuracao.notificacoesEmails[index] = UtilitarioGeral.instanciaObjetoLocal(state.notificacaoEmailEmEdicao);
        state.notificacaoEmailEmEdicao = {} as IConfiguracaoNotificacaoEmail;
      }
      state.apresentarModalNotificacao = false;
    }

    function copiarVariavel(variavel:string) {
      navigator.clipboard.writeText(variavel);
    }

    onBeforeMount(async () => {
      limparDados();
      appBase.carregando = true;
      state.configuracao = await servicoApp.obterConfiguracoes();
      appBase.carregando = false;
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as suas Configurações.');
      appBase.resposta = await servicoApp.atualizarConfiguracoesEmails(state.configuracao);
      apresentarResposta();
    }

    return {
      appBase,
      state,
      salvar,
      obterDescricaoTipoNotificacao,
      apresentarModalEdicaoNotificacao,
      confirmarAlteracoesNotificaoEmail,
      copiarVariavel,
      obterDestintariosTipoNotificacaoEmail,
      preencherDestintariosTipoNotificacaoEmail,
    };
  },
});
