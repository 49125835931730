
import { computed, defineComponent, ref } from 'vue';
import { Money3Component } from 'v-money3';
import { IConfiguracaoVMoney } from '@/core/models/Mascara/IConfiguracaoVMoney';
import { useAppBase } from '@/core/composables/AppBase';

export default defineComponent({
  name: 'CampoNumerico',
  components: {
    money3: Money3Component,
  },
  props: {
    valor: {
      type: Number,
      default: 0,
    },
    quantidadeCasasDecimais: {
      type: Number,
      default: 2,
    },
    monetario: {
      type: Boolean,
      default: false,
    },
    percentual: {
      type: Boolean,
      default: false,
    },
    mascara: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    permitirValorNegativo: {
      type: Boolean,
      default: false,
    },
    classCss: {
      type: String,
      default: '',
    },
    classGroupCss: {
      type: String,
      default: '',
    },
    valorMaximo: {
      type: Number,
      default: 0,
    },
    fracionar: {
      type: Boolean,
      default: true,
    },
    info: {
      type: String,
      default: '',
    },
  },
  emits: ['update:valor', 'change', 'blur', 'valorDigitado'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useAppBase();
    const refCampoNumerico = ref<HTMLElement | null>(null);

    function obterConfiguracao():IConfiguracaoVMoney {
      const configuracaoVMoney:IConfiguracaoVMoney = {} as IConfiguracaoVMoney;
      configuracaoVMoney.masked = false;
      configuracaoVMoney.prefix = '';
      configuracaoVMoney.prefix = '';
      configuracaoVMoney.suffix = '';
      configuracaoVMoney.thousands = '.';
      configuracaoVMoney.decimal = ',';
      configuracaoVMoney.precision = props.quantidadeCasasDecimais;
      configuracaoVMoney.disableNegative = (!props.permitirValorNegativo);
      configuracaoVMoney.disabled = props.disabled;
      configuracaoVMoney.allowBlank = false;
      configuracaoVMoney.minimumNumberOfCharacters = 0;

      return configuracaoVMoney;
    }

    const computedValor = computed({
      get: () => props.valor,
      set: (valor: number) => {
        if (props.valorMaximo > 0 && valor > props.valorMaximo && !props.disabled) {
          apresentarMensagemAlerta(`O valor ${valor} é maior que o valor máximo de ${props.valorMaximo}`);
          emit('update:valor', 0);
        } else if (props.fracionar) {
          emit('update:valor', valor);
        } else if (!props.fracionar) {
          if (valor < 1) {
            const novoValor = valor * 10;
            emit('update:valor', novoValor.toFixed(props.quantidadeCasasDecimais));
          } else if (valor > 1 && valor.toString().includes('.')) {
            const valorInteiro = Math.trunc(valor);
            emit('update:valor', valorInteiro.toFixed(props.quantidadeCasasDecimais));
          } else {
            emit('update:valor', valor);
          }
        }
      },
    });

    function change() {
      emit('change', computedValor.value);
    }

    function focus() {
      if (refCampoNumerico.value) {
        refCampoNumerico.value.focus();
      }
    }

    function valorDigitado(event : any) {
      if ((event.keyCode >= 48 && event.keyCode <= 57)
    || (event.keyCode >= 96 && event.keyCode <= 105)
    || event.keyCode === 46 || event.keyCode === 110 || event.keyCode === 190
    || event.keyCode === 8 || event.keyCode === 194 || event.keyCode === 219) {
        emit('valorDigitado');
      }
    }
    function blur() {
      emit('blur', computedValor.value);
    }
    return {
      props,
      computedValor,
      refCampoNumerico,
      obterConfiguracao,
      focus,
      change,
      blur,
      valorDigitado,
    };
  },
});
